import { isValid, format, parseISO } from 'date-fns/esm';

export const formatIsoDateToHuman = (date: string, locale: string) => {
  const val = parseISO(date);
  let dateFormatted = isValid(val) ? format(val, 'dd MMMM yyyy') : '';
  if (locale === 'ru') {
    const monthNamesRuLang = [
      '',
      'Января',
      'Февраля',
      'Марта',
      'Апреля',
      'Мая',
      'Июня',
      'Июля',
      'Августа',
      'Сентября',
      'Октября',
      'Ноября',
      'Декабря',
    ];
    const monthRuLang = monthNamesRuLang[Number(date.split('-')[1])];
    const dayRuLang = dateFormatted.split(' ')[0];
    const yearRuLang = dateFormatted.split(' ')[2];

    dateFormatted = `${dayRuLang} ${monthRuLang} ${yearRuLang}`;
  }

  return dateFormatted;
};
